import { getMountedApps } from 'single-spa'
export default function () {
  return Promise.all([
    System.import('rxjs'),
    System.import('rxjs/operators'),
  ]).then(([rxjs, operators]) => {
    // setup stream so that we can capture data on each route change
    const routingEvt$ = rxjs.fromEvent(window, 'single-spa:routing-event')
    // subscribing to only the first value with no filtering
    routingEvt$
      .pipe(
        operators.first(),
        operators.map(() => [getMountedApps(), window.location]),
      )
      .subscribe(([mountedApps, location]) => {
        reportDisaggregationMetric(mountedApps, location)
      })
    // create stream for each change and using filtering to ensure we don't get duplicate URLs one after another
    const mountedApps$ = routingEvt$.pipe(
      operators.filter(
        (evt) => evt.detail && evt.detail.oldUrl !== evt.detail.newUrl,
      ),
      operators.map(() => [getMountedApps(), window.location]),
    )

    const sub = mountedApps$.subscribe(([mountedApps, location]) => {
      reportDisaggregationMetric(mountedApps, location)
    })

    window.addEventListener('beforeunload', () => {
      sub?.unsubscribe()
    })
  })
}

function ignoreKaminoAndNavigationMFEs(appName) {
  return !(
    appName.startsWith('@wf-mfe/navigation') ||
    appName.startsWith('@wf-mfe/kamino')
  )
}

export function reportDisaggregationMetric(mountedApps, location) {
  const DDReady =
    window.DD_RUM &&
    window.DD_RUM.addAction &&
    typeof window.DD_RUM.addAction === 'function'
  // Some environments intentionally don't have DataDog. We shouldn't throw errors in those ENVs
  if (DDReady) {
    const isDisaggregated = !mountedApps.includes('@wf-mfe/quicksilver')
    window.DD_RUM.addAction(`isPathDisaggregatedMFE`, {
      path: removeObjectIdsFromUrl(location.pathname),
      isDisaggregated,
    })

    const nonArchitecturalApps = mountedApps.filter(
      ignoreKaminoAndNavigationMFEs,
    )
    const isPartiallyDisaggregated =
      nonArchitecturalApps.length > 1 ||
      nonArchitecturalApps.includes('@wf-mfe/quicksilver') === false

    window.DD_RUM.addAction(`isPathPartiallyDisaggregatedMFE`, {
      path: removeObjectIdsFromUrl(location.pathname),
      isPartiallyDisaggregated,
    })
  }
}

const RRObjectIDRegex = /[a-f0-9]{32}|(\d+)/g
export function removeObjectIdsFromUrl(url) {
  return url.replace(RRObjectIDRegex, '?')
}
