import bootstrapSPA from './bootstrap/spa-bootstrapper.js'

export { bootstrapSPA }

async function bootstrapNwe() {
  const [{ renderNwe }] = await Promise.all([
    import('@wf-mfe/navigation'),
    bootstrapSPA(),
  ])
  renderNwe()
}

if (
  window.config?.isPolar === undefined &&
  window.WF_API_ENDPOINT_HOSTNAME === undefined
) {
  bootstrapNwe()
}
