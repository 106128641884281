let logger
export default function initializeLogger() {
  return System.import('@wf-mfe/logger').then(
    ({ initalizeDataDogGlobalContexts }) => {
      return initalizeDataDogGlobalContexts()
    },
  )
}

export function loadLoggerAndLogError(err, rethrow = false) {
  const maybeRethrow = () => {
    if (rethrow) {
      throw err
    }
  }

  return getOrLoadLogger()
    .then((logger) => {
      logger.error(err)
    })
    .then(maybeRethrow)
}

export function getOrLoadLogger() {
  if (logger !== undefined) {
    return Promise.resolve(logger)
  } else {
    return System.import('@wf-mfe/logger').then((mfeLogger) => {
      logger = mfeLogger.createMFELogger({ name: 'mfe-config' })
      return logger
    })
  }
}
