export default function initializePerformanceMetrics() {
  window.addEventListener(
    'single-spa:before-first-mount',
    () => performance.mark('nwe_sspa_before_first_mount'),
    { once: true },
  )
  window.addEventListener(
    'single-spa:first-mount',
    () => {
      performance.mark('nwe_sspa_first_mount')
      performance.measure(
        'nwe_sspa_time_to_mount_first_application',
        'nwe_sspa_before_first_mount',
        'nwe_sspa_first_mount',
      )
    },
    { once: true },
  )

  window.addEventListener('single-spa:app-change', (evt) => {
    evt.detail.appsByNewStatus.MOUNTED.forEach((name) => {
      performance.mark(`nwe_mfe_config_mounted_${name}`)
      performance.measure(
        `nwe_mfe_config_ttm_${name}`,
        `nwe_mfe_config_import_${name}`,
        `nwe_mfe_config_mounted_${name}`,
      )
    })
  })

  return Promise.resolve()
}
