import {
  getLaunchConfig,
  getLaunchScriptSrc,
} from '@workfront/mfe/plugins/launch'

export default async function initializeLaunchPolar() {
  const launchConfig = await getLaunchConfig()
  const src = getLaunchScriptSrc(window.config?.LANE || 'devtest')

  if (!window.digitalData) {
    window.digitalData = {}
  }

  window.digitalData.user = launchConfig.user
  window.digitalData.account = launchConfig.account
  window.digitalData.producedBy = 'Polar App'

  const newElement = document.createElement('script')
  newElement.src = src

  document.body.appendChild(newElement)
}
