export default function () {
  return Promise.resolve().then(() => {
    if (window.wf_inspector?.isDevtoolsEnabled()) {
      return System.import('@wf-mfe/workfront-inspector')
        .then((workfrontInspector) =>
          (workfrontInspector.default || workfrontInspector)({
            showPolarSwitcher: true,
          }),
        )
        .catch((err) => {
          console.error('Error loading Workfront Inspector', err)
        })
    }
  })
}
