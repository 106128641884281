export default async function initializeJumpSeat() {
  if (window.config?.publicToken) {
    return
  }

  await new Promise((resolve) => {
    // This timeout is agreed upon by Product and Engineering (also working with
    // Jumpseat folks). At last measure, this puts the call happening at 6.5 sec
    // for p50. Some jumpseat guides include page refreshes, so with this
    // timeout customers experiencing 10-12 seconds until they see the next step.
    //    We can lower this timeout at anytime, but should not increase it
    // without product involvement. Changes to the number require doc updates.
    setTimeout(resolve, 5e3)
  })
  const { wfetch } = await System.import('@wf-mfe/api')

  const packagingOptions = await wfetch(
    '/attask/api-internal/customer?action=getPackagingOptionValues&method=PUT',
  )

  if (packagingOptions.JumpSeatIntegration) {
    return getJumpSeatConfigData()
  }
}

async function getJumpSeatConfigData() {
  const { wfetch, getAuthHeaders } = await System.import('@wf-mfe/api')

  try {
    const customerInfo = await wfetch(
      '/jumpseat/api/jumpseat/v1/configuration',
      {
        method: 'GET',
        credentials: 'include',
        headers: getAuthHeaders(),
      },
      { preventAutoRedirect: true },
    ).then((response) => response.json())

    // Handle customerInfo and inject script
    if (customerInfo?.isActive) {
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = `${customerInfo.url}/aerospace?ref=${window.location.origin}`
      document.body.appendChild(script)
    }
  } catch (e) {
    console.error('Failed to fetch JumpSeat configuration', e)
  }
}
